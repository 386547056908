import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';

@Injectable({
    providedIn: 'root'
})
export class RouteValidator implements CanActivate {

    constructor(private _router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const accessToken = next.queryParamMap.get("access_token");
        const isNextRouteValid = accessToken;
        if (!isNextRouteValid) {
            this._router.navigate(['/notfound']);
            return false;
        }
        return true;
    }
}
