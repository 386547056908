import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { AuthGuard } from './auth/auth.guard';
import { RouteValidator } from './routevalidations/route.validator';
import { NotFoundComponent } from './not-found/not-found.component';
// import { DiaryComponent } from './diary/diary.component';

export const appRoutes: Routes = [
    // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate:[RouteValidator] },
    // {
    //     path: 'signup', component: UserComponent,
    //     children: [{ path: '', component: SignUpComponent }]
    // },
    // {
    //     path: 'login', component: UserComponent,
    //     children: [{ path: '', component: SignInComponent }]
    // },
    // { path: 'diary', component: DiaryComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo:"/home", pathMatch:"full"},
    { path: '**', pathMatch: 'full', component: NotFoundComponent }
];
